import $ from "jquery"
import Swiper from "swiper/bundle"
const { __ } = wp.i18n

class ProjektLoader {
  constructor(projektID, initialSlide) {
    // console.log("new projekt loader")
    this.homeID = $("body").data("home-id").toString() // post id of the home projekt as string
    this.projektID = projektID ?? 0
    this.loadedProjektIDs = [] // array of all loaded projekts IDs
    this.requestedProjektIDs = [] // array of requested but not yet loaded projekts

    this.root_url = localized?.root_url ?? ""

    // intersection observer for triggering current projekt and projekt is visible
    this.mobileBreakpoint = 782
    this.mobileView = window.innerWidth < this.mobileBreakpoint
    this.adminBarhHeight = this.getAdminBarHeight()
    this.siteHeaderHeight = this.getSiteHeaderHeight()
    this.scrollTime = 400

    this.projektLoading // requested projekt is loading (not prev / next loadprojekt)

    this.postsContainer = $(".posts-container")
    this.currentProjekt = $(`div#${projektID}`)

    this.scrollY = 0
    this.scrollDir = true // true == scroll down
    this.scrollToProjekt = [] // array with objects: projekt id, scrollTo. whether to scroll to the loaded projekt or not
    this.scrollToCounter = 0

    this.resizeTimeout = null
    this.autoScrolling = false // true = $ animate scrolltop
    this.autoScrollingTimeout // timeout for resetting the autoscrolling after scrolll ended

    this.currentProjektID // callback function for the current projekt id
    this.events({ after: true })
    this.blogName = document.querySelector('meta[name="name"]').content

    // this.reqProjektID = projektID // the projekt id currently requested to load and scroll to. not loaded by projekt observer

    if (this.currentProjekt.length == 0) {
      // projekt not loaded yet
      this.loadProjekt(projektID, initialSlide) // load projekt and scroll to it
    } else {
      // projekt loaded (by php)
      // console.log("projekt " + projektID + " loaded by php")
      // $(`#${projektID}`).remove() // remove the projekt item loaded by php. we load all projekts in the correct order again
      this.loadProjekt(projektID, initialSlide) // load projekt and scroll to it
    }
  }

  events(args) {
    const { after } = args
    document.addEventListener("scroll", () => {
      this.scrollDir = window.scrollY >= this.scrollY // true = down to bottom, false = up to the top
      this.scrollY = window.scrollY
    })

    window.addEventListener("resize", () => {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.resizeFunction()
      }, 750)
    })
  }

  resizeFunction() {
    // intersection observe: cannot change root margin
    // must be reinstantiated with different values
    this.mobileView = window.innerWidth < this.mobileBreakpoint
    this.adminBarhHeight = this.getAdminBarHeight()
    this.siteHeaderHeight = this.getSiteHeaderHeight()
    // console.log("resize event in projekt loader", this.marginTop, this.siteHeaderHeight, this.mobileView)
  }

  //
  async loadProjekt(projektID, initialSlide) {
    // -> indicate projekt loading in progress. disable other projekt load
    this.projektLoading = true
    // this.projektLoadingExtended = true // indicate loadprojekt is running

    // console.log("-------loadProjekt", projektID)

    const $reqProjekt = $(`div#${projektID}`)

    const allProjekts = $(".post-item-container") // get ref to all projekt items

    if ($reqProjekt.length == 0) {
      //  check if projekt is already requested
      if (!this.requestedProjektIDs.includes(projektID)) {
        // requested projekt does not exist and is not currently being loaded -> load it and all siblings of it
        $("body").addClass("single-projekt")
        allProjekts.hide() // hide all projekts

        this.requestedProjektIDs.push(projektID)

        // let reqProjektIDs = "" // ids string for get request. we load all sibling projekts at once
        // siblings.forEach((id, index) => {
        //   reqProjektIDs = reqProjektIDs + `${index > 0 ? `,` : ``}${id}`
        // })

        // console.log(`requested projekt not yet existing -> load projekt: ${projektID} of ${reqProjektIDs}`)

        try {
          await $.getJSON(localized.root_url + "/wp-json/wp/v2/projekt/" + projektID + "?acf_format=standard", projekt => {
            // console.log(`projekt received`, projekt)

            const newProjekt = $(
              `
                  <div id="${projekt.id}" class="post-item-container post-type-projekt single-projekt-item initialized$" data-title="${projekt.title.rendered}" data-href="${projekt.link}" data-history="${projekt.slug}">
                  
                    <div class="single-projekt-item__number">${projekt.acf.projekt_nummer}</div>

                    <div class="single-projekt-item__close-button hamburg checked">
                      <a href="${localized.home_url}/projekte/">
                          <div class="line"></div>
                          <div class="line"></div>
                          <div class="line"></div>
                      </a>
                    </div>

                    <div class="single-projekt-item__content">

                      <div class="single-projekt-item__info">
                        ${projekt.acf.projekt_bezeichnung} <br>
                        ${projekt.acf.projekt_standort ? projekt.acf.projekt_standort : ""}${projekt.acf.projekt_standort && projekt.acf.projekt_planung_ausfuehrung ? `, ${projekt.acf.projekt_planung_ausfuehrung}` : projekt.acf.projekt_planung_ausfuehrung}
                        
                        ${projekt.acf.projekt_standort || projekt.acf.projekt_planung_ausfuehrung ? "<br>" : ""}
                        ${projekt.acf.projekt_aquisition}${projekt.acf.projekt_aquisition && projekt.acf.projekt_wettbewerb_jahr ? ", " : ""}
                        ${projekt.acf.projekt_wettbewerb_jahr}${(projekt.acf.projekt_aquisition || projekt.acf.projekt_wettbewerb_jahr) && projekt.acf.projekt_rang ? ", " : ""}${projekt.acf.projekt_rang}
                      </div>

                      <div class="swiper single-projekt-item__swiper">
                        <div class="swiper-wrapper">
                        ${
                          projekt.images_full
                            ? projekt.images_full
                                .map(
                                  img =>
                                    `<div class="swiper-slide project-item__image">
                                <div class="image-wrapper">
                                  <img data-src="${img.url}" data-srcset="${img.srcset}" data-sizes="(max-width: 781px) 100vw, (max-width: 1799px) calc(100vw - 260px), 1580px" 
                                    alt="${img.alt}" class="swiper-lazy" width="${img.width}px" height="${img.height}px">
                                </div>
                                <div class="caption">${img.caption}</div>
                                <div class="swiper-lazy-preloader"></div>
                              </div>`
                                )
                                .join("")
                            : ""
                        }
                        </div>

                        <div class="swiper-pagination"></div>

                        <div class="swiper-button-prev"></div>
                        <div class="swiper-button-next"></div>
                      
                      </div>

                      <div class="single-projekt-item__text">
                      ${projekt.content.rendered}
                      </div>

                      <div class="single-projekt-item__data">

                      ${
                        projekt.acf.projekt_mitarbeit_wettbewerb
                          ? `<p>
                      <strong>${__("Mitarbeit Wettbewerb", "emi")}</strong><br />
                      ${projekt.acf.projekt_mitarbeit_wettbewerb}
                      </p>`
                          : ""
                      }
                      ${
                        projekt.acf.projekt_mitarbeit_planung_ausfuehrung
                          ? `<p>
                      <strong>${__("Mitarbeit Planung und Ausführung", "emi")}</strong><br />
                      ${projekt.acf.projekt_mitarbeit_planung_ausfuehrung}
                      </p>`
                          : ""
                      }
                      ${
                        projekt.acf["projekt_bs-emi"]
                          ? `<p>
                      <strong>${__("Zusammenarbeit", "emi")}</strong><br />
                        Baumberger Stegmeier Architektur (<a href="http://www.bs-emi.ch">BS+EMI Architektenpartner AG</a>)
                      </p>`
                          : ""
                      }
                      ${
                        projekt.acf.projekt_zusammenarbeit
                          ? `<p>
                      <strong>${__("Zusammenarbeit", "emi")}</strong><br />
                        ${projekt.acf.projekt_zusammenarbeit}
                      </p>`
                          : ""
                      }
                      ${
                        projekt.acf.projekt_bauherrschaft
                          ? `<p>
                      <strong>${__("Bauherrschaft", "emi")}</strong><br />
                        ${projekt.acf.projekt_bauherrschaft}
                      </p>`
                          : ""
                      }
                      
                      ${
                        projekt.acf.projekt_fachplaner
                          ? `<p>
                        ${projekt.acf.projekt_fachplaner
                          .map(
                            item => `
                              <strong>${item.projekt_fachplaner_bezeichnung}:</strong>
                              ${item.projekt_fachplaner_unternehmer}<br />   
                            `
                          )
                          .join("")}
                        </p>`
                          : ""
                      }

                      ${
                        projekt.acf.projekt_fotograf
                          ? `<p>
                      <strong>${__("Fotos", "emi")}</strong><br />
                        ${projekt.acf.projekt_fotograf}
                      </p>`
                          : ""
                      }

                      ${
                        projekt.acf.projekt_visualisierung
                          ? `<p>
                      <strong>${__("Visualisierungen", "emi")}</strong><br />
                        ${projekt.acf.projekt_visualisierung}
                      </p>`
                          : ""
                      }

                      ${
                        projekt.acf.projekt_publikation
                          ? `<p>
                        ${projekt.acf.projekt_publikation
                          .map(
                            item => `
                              ${
                                item.projekt_publikation_link
                                  ? `
                              <a href=${item.projekt_publikation_link} target="_blank">${item.projekt_publikation_name}</a>
                              `
                                  : `${item.projekt_publikation_name}`
                              } <br />
                            `
                          )
                          .join("")}
                        </p>`
                          : ""
                      }
                      
                      ${
                        projekt.acf.projekt_auszeichnung
                          ? `<p>
                        ${projekt.acf.projekt_auszeichnung
                          .map(
                            item => `
                              ${
                                item.projekt_auszeichnung_link
                                  ? `
                              <a href=${item.projekt_auszeichnung_link} target="_blank">${item.projekt_auszeichnung_name}</a>
                              `
                                  : `${item.projekt_auszeichnung_name}`
                              } <br />
                            `
                          )
                          .join("")}
                        </p>`
                          : ""
                      }

                      ${
                        projekt.acf.projekt_anhang
                          ? `
                          <a href=${projekt.acf.projekt_anhang.url} target="_blank">${projekt.acf.projekt_anhang.title}</a>`
                          : ""
                      }







                      </div>





                    </div>
                      
                    
                  </div>`
            ).appendTo(this.postsContainer)

            this.createProjektSwiper(newProjekt, initialSlide)

            // // add the observers for the new projekt
            // newProjekt.filter(".post-item-container").each((index, item) => {
            //   this.observeProjekt($(item))
            //   this.setPathForProjektTitle($(item).attr("id"))
            // })
          })

          // this.currentProjekt = $(`div#${projektID}`) // store new projekt as the current projekt
          // this.projektID = projektID

          // $(`${sibString}`)
          //   .stop()
          //   .fadeIn(800, async () => {}) // fade in the new projekts
          // this.lastVisibleProjektCheck()
          // await this.scrollTo(this.currentProjekt, 0)

          this.removeIDfromRequestedProjektIDs(projektID)

          this.projektLoading = false // indicate requested projekt load done
          // console.log("+++++ (new) projekt load finished", projektID)
          //
        } catch (error) {
          console.error("(new )projekt load error", error)
          // delete id from the requested projekts ids
          this.removeIDfromRequestedProjektIDs(projektID)
        }

        this.projektLoading = false
        // clearTimeout(this.projektLoadingExtendedTimeout)
        // this.projektLoadingExtendedTimeout = setTimeout(() => (this.projektLoadingExtended = false), 500)
      }
    } else {
      // projekt exists -> show it
      // console.log(`projekt ${projektID} exists -> show it`)
      $("body").addClass("single-projekt")
      this.projektID = projektID
      this.currentProjekt = $reqProjekt // set requested projekt as current projekt
      allProjekts.hide()
      $reqProjekt.show()
      this.createProjektSwiper($reqProjekt)
      const swiper = $reqProjekt.find(".swiper-initialized")[0].swiper
      swiper.slideToLoop(initialSlide, 0)
      // scroll to top
      await this.scrollTo(this.currentProjekt, 0)
    }
  }

  removeIDfromRequestedProjektIDs(projektID) {
    const idx = this.requestedProjektIDs.indexOf(projektID)
    if (idx >= 0) delete this.requestedProjektIDs[idx]
  }

  createProjektSwiper(projekt, initialSlide) {
    const swipers = projekt.find(".swiper:not(.swiper-initialized)")
    if (swipers.length) {
      const that = this
      swipers.each(function () {
        // console.log("create project swiper", initialSlide)
        const swiper = new Swiper($(this)[0], {
          initialSlide: initialSlide ? initialSlide : 0,
          navigation: {
            enabled: false,
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            enabled: true
          },
          breakpoints: {
            782: {
              navigation: {
                enabled: true
              },
              pagination: {
                enabled: false
              }
            }
          },
          // Disable preloading of all images
          preloadImages: false,
          //          Enable lazy loading
          lazy: {
            checkInView: true,
            loadPrevNext: true
          },
          // watchSlidesProgress: true,
          slidesPerView: 1,
          loop: true,
          mousewheel: {
            forceToAxis: true
          },
          ...that.shouldBeEnabled($(this)[0])
        })
      })
    }
  }

  shouldBeEnabled(carousel) {
    let settings
    const wrapperWidth = carousel.querySelector(".swiper-wrapper").offsetWidth
    const slides = carousel.querySelectorAll(".swiper-slide")
    if (slides.length) {
      const slidesWidth = [...slides].map(el => $(el).outerWidth()).reduce((total, width) => total + width)
      if (slidesWidth < wrapperWidth) {
        settings = {
          loop: false,
          enabled: false
        }
        return settings
      }
    }
    return null
  }

  shouldBeEnabledVertical(carousel) {
    let settings
    const wrapperHeight = carousel.querySelector(".swiper-wrapper").offsetHeight
    let slidesHeight = [...carousel.querySelectorAll(".home-swiper-slide")]
    slidesHeight = slidesHeight
      .map(el => {
        return $(el).outerHeight(true)
      })
      .reduce((total, height) => total + height)

    if (slidesHeight < wrapperHeight) {
      settings = {
        loop: false,
        enabled: false
      }
      return settings
    }
    return null
  }

  getAdminBarHeight() {
    let ah = 0
    const adminbar = document.querySelector("#wpadminbar")
    if (adminbar) ah = adminbar.offsetHeight // height including: padding and border, without margin
    return ah
  }

  getSiteHeaderHeight() {
    // header height = site header height
    let hh = 0
    if (this.mobileView) hh = this.mobileHeaderHeight
    else hh = document.querySelector(".site-header").offsetHeight
    return hh
  }

  scrollTo(projekt, time) {
    time = time ?? this.scrollTime
    return new Promise((resolve, reject) => {
      this.scrollToCounter = 0 // reset the scroll counter
      this.scrollFunc(projekt, time, resolve)
    })
  }

  scrollFunc(projekt, time, resolve) {
    const top = this.getElementTop(projekt)

    // console.log("->->->->---- scroll to projekt:", projekt.attr("id"), top, $("html").scrollTop(), window.scrollY)

    this.autoScrolling = true // indicate scrolling in progress
    this.scrollToCounter++

    $("html, body")
      .stop(true, true)
      .animate(
        {
          scrollTop: top
        },
        time,
        () => {
          // complete

          clearTimeout(this.autoScrollingTimeout)

          this.autoScrollingTimeout = setTimeout(() => {
            // check if position is correct
            // const top = this.getElementTop(projekt)
            // if (top != window.scrollY && this.scrollToCounter < 10) {
            //   // position is incorrect -> scroll again. limit to 10 scroll calls
            //   this.scrollFunc(projekt, time, resolve)
            // } else {
            this.autoScrolling = false
            resolve()
            // console.log("scrollTo finished", projekt.attr("id"), window.scrollY, top, $("html").scrollTop())
            // }
          }, 250)
        }
      )
  }

  getElementTop(projekt) {
    let top = 0
    if (this.mobileView) {
      const siteHeader = $(".site-header")
      if (siteHeader.hasClass("collapsed")) {
        top = Math.max(0, Math.round(projekt.offset().top - this.adminBarhHeight - this.mobileHeaderHeight))
      } else {
        top = Math.max(0, Math.round(projekt.offset().top - this.adminBarhHeight - siteHeader.outerHeight()))
      }
    } else top = Math.max(0, Math.round(projekt.position().top))

    return top
  }

  jumpTo(top) {
    return new Promise((resolve, reject) => {
      window.scrollTo(0, top)

      setTimeout(() => {
        window.scrollTo(0, top)
        resolve()
      }, 100)
      setTimeout(() => {
        window.scrollTo(0, top)
        resolve()
      }, 200)
    })
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default ProjektLoader

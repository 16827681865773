import $ from "jquery"
import Swiper from "swiper/bundle"

class PageLoader {
  constructor(pageID, template) {
    this.homeID = $("body").data("home-id").toString() // post id of the home page as string
    this.pageID = pageID ?? 0
    this.loadedPageIDs = [] // array of all loaded pages IDs
    this.requestedPageIDs = [] // array of requested but not yet loaded pages
    this.root_url = localized?.root_url ?? ""

    // intersection observer for triggering current page and page is visible
    this.pageEnterObserver
    this.stickyTitleObserver
    this.stickyTitleEnterObserver
    this.mobileBreakpoint = 782
    this.mobileView = window.innerWidth < this.mobileBreakpoint
    this.mobileHeaderHeight = 60
    this.marginTop = this.getMarginTop() // for intersection observer margin top
    this.adminBarhHeight = this.getAdminBarHeight()
    this.siteHeaderHeight = this.getSiteHeaderHeight()
    this.observerHeight = 200
    this.scrollTime = 400

    this.pageLoading // requested page is loading (not prev / next loadpage)
    // this.pageLoadingExtended // when load page is executing and shortly after
    // this.pageLoadingExtendedTimeout // for resetting the variable to false

    this.postsContainer = $(".posts-container")
    this.currentPage = $(`div#${pageID}`)

    this.scrollY = 0
    this.scrollDir = true // true == scroll down
    this.scrollToPage = [] // array with objects: page id, scrollTo. whether to scroll to the loaded page or not
    this.scrollToCounter = 0
    this.resizeTimeout = null
    this.autoScrolling = false // true = $ animate scrolltop
    this.autoScrollingTimeout // timeout for resetting the autoscrolling after scrolll ended

    this.currentPageID // callback function for the current page id
    this.events({ after: true })

    // this.reqPageID = pageID // the page id currently requested to load and scroll to. not loaded by page observer

    if (this.currentPage.length == 0) {
      // page not loaded yet
      this.loadPage(pageID) // load page and scroll to it
    } else {
      // page loaded (by php)
      // console.log("page " + pageID + " loaded by php")
      // $(`#${pageID}`).remove() // remove the page item loaded by php. we load all pages in the correct order again
      this.loadPage(pageID) // load page and scroll to it
    }
  }

  events(args) {
    const { after } = args
    document.addEventListener("scroll", () => {
      this.scrollDir = window.scrollY >= this.scrollY // true = down to bottom, false = up to the top
      this.scrollY = window.scrollY
    })

    window.addEventListener("resize", () => {
      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        this.resizeFunction()
      }, 750)
    })
  }

  resizeFunction() {
    // intersection observe: cannot change root margin
    // must be reinstantiated with different values
    this.mobileView = window.innerWidth < this.mobileBreakpoint
    this.marginTop = this.getMarginTop()
    this.adminBarhHeight = this.getAdminBarHeight()
    this.siteHeaderHeight = this.getSiteHeaderHeight()
    // console.log("resize event in page loader", this.marginTop, this.siteHeaderHeight, this.mobileView)

    if (this.pageEnterObserver) {
      this.pageEnterbserver.disconnect()
      this.pageEnterObserver = null
    }
    if (this.stickyTitleObserver) {
      this.stickyTitleObserver.disconnect()
      this.stickyTitleObserver = null
    }
    if (this.stickyTitleEnterObserver) {
      this.stickyTitleEnterObserver.disconnect()
      this.stickyTitleEnterObserver = null
    }
    $(".post-item-container.post-type-page").each((index, item) => {
      this.observePage($(item))
    })
  }

  //
  async loadPage(pageID, template) {
    // -> indicate page loading in progress. disable other page load
    this.pageLoading = true
    // this.pageLoadingExtended = true // indicate loadpage is running

    // console.log("-------loadPage", pageID)

    let $reqPage = $(`div#${pageID}`)
    const allPages = $(".post-item-container") // get ref to all page items

    // const siblings = this.getPageSiblingsID(pageID) // array of siblings page ids
    // const sibString = this.makeSiblingsString(siblings) // siblings string for $ hide

    if ($reqPage.length == 0) {
      //  check if page is already requested
      // console.log("no page yet")
      window.location.replace(window.location.href)
      // if (!this.requestedPageIDs.includes(pageID)) {
      // }
    } else {
      // page exists -> show it
      // console.log(`page ${pageID} exists -> show it`)

      // check if page is in another page container (if is a subpage )
      const parentPage = $reqPage.parents(".post-item-container.post-type-page")
      if (parentPage.length) {
        // is a subpage -> show the parent page
        this.pageID = parentPage.attr("id")
        $reqPage = $(`#${this.pageID}`)
      } else {
        // is not a subpage -> show the page
        this.pageID = pageID
        this.currentPage = $reqPage // set requested page as current page
      }
      allPages.hide()
      $reqPage.show()
      this.createPageSwiper($reqPage)
    }
  }

  createPageSwiper(page) {
    // create site swiper first
    const swiper = page.find(".site-swiper:not(.swiper-initialized)")
    if (swiper.length) {
      // console.log("create  site swiper")
      new Swiper(swiper[0], {
        // Disable preloading of all images
        // preloadImages: false,
        // Enable lazy loading
        // lazy: {
        //   checkInView: true
        // },
        watchSlidesProgress: true,
        slidesPerView: "auto",
        freeMode: true,
        loop: this.mobileView ? false : true,
        enabled: this.mobileView ? false : true,
        direction: "vertical",
        mousewheel: {
          forceToAxis: true
        },
        ...this.shouldBeEnabledVertical(swiper[0])
      })
    }

    // create horizontal swipers
    const swipers = page.find(".swiper:not(.swiper-initialized, .site-swiper)")
    if (swipers.length) {
      const that = this
      swipers.each(function () {
        // console.log("create project swiper")
        const swiper = new Swiper($(this)[0], {
          // Disable preloading of all images
          preloadImages: false,
          //          Enable lazy loading
          lazy: {
            checkInView: true,
            loadPrevNext: true
          },
          watchSlidesProgress: true,
          slidesPerView: "auto",
          freeMode: {
            momentum: true,
            momentumBounce: false,
            momentumRation: 2,
            sticky: false
          },
          spaceBetween: 10,
          loop: true,
          mousewheel: {
            forceToAxis: true
          },
          ...that.shouldBeEnabled($(this)[0]),
          on: {
            setTranslate: function (swiper, translate) {
              // update swiper-wrapper x position of duplicate sliders
              const $swiper = $(swiper.$el)
              const id = $(swiper.$el).children(".swiper-wrapper").attr("id")
              const aria = $(swiper.$el).attr("aria-label")
              const duplicates = $(".site-swiper ").find(`[aria-label='${aria}']`).not($swiper).children(".swiper-wrapper")
              if (duplicates.length) duplicates.css("transform", `translate3d(${translate}px, 0px, 0px)`)
            }
          }
        })
      })
    }
  }

  shouldBeEnabled(carousel) {
    let settings
    const wrapperWidth = carousel.querySelector(".swiper-wrapper").offsetWidth
    const slidesWidth = [...carousel.querySelectorAll(".swiper-slide:not(.swiper-slide-duplicate)")].map(el => $(el).outerWidth()).reduce((total, width) => total + width)
    if (slidesWidth < wrapperWidth) {
      settings = {
        loop: false,
        enabled: false
      }
      return settings
    }
    return null
  }

  shouldBeEnabledVertical(carousel) {
    let settings
    const wrapperHeight = carousel.querySelector(".site-swiper > .swiper-wrapper").offsetHeight
    let slidesHeight = [...carousel.querySelectorAll(".home-swiper-slide:not(.swiper-slide-duplicate)")]
    slidesHeight = slidesHeight
      .map(el => {
        // console.log("element outer height", $(el).outerHeight(true))
        return $(el).outerHeight(true)
      })
      .reduce((total, height) => total + height)

    // console.log("slidesheight", slidesHeight, "wrapperheight", wrapperHeight)

    if (slidesHeight < wrapperHeight) {
      settings = {
        loop: false,
        enabled: false
      }
      return settings
    }
    return null
  }

  removeIDsfromRequestedPageIDs(siblings) {
    siblings.forEach(pageID => {
      const idx = this.requestedPageIDs.indexOf(pageID)
      if (idx >= 0) delete this.requestedPageIDs[idx]
    })
    // console.log("req page id after", this.requestedPageIDs)
  }

  lastVisiblePageCheck() {
    // find the last visible page (".post-item-container") and set the min-height to 100vh to ensure scrolling is possible

    const allPages = $(".post-item-container")

    // get the last visible page (-> min  height 100vh)
    const lastVisiblePage = allPages.filter(":visible").last()

    if (lastVisiblePage.length) {
      allPages.not(lastVisiblePage).removeClass("post-item--last-page")

      lastVisiblePage.addClass("post-item--last-page")

      // console.log("***** check last visible page:", lastVisiblePage.attr("id"), lastVisiblePage.data("title"), lastVisiblePage.position().top, lastVisiblePage.height(), window.innerHeight)
    } else {
      allPages.removeClass("post-item--last-page")
    }
  }

  removeLastPageClassIfNotLastPage(siblings) {
    // siblings = array of sibling ids
    const allPages = $(".post-item-container")

    // get the last visible page (-> min  height 100vh)
    const lastVisiblePage = allPages.filter(":visible").last()
    if (lastVisiblePage.length) {
      allPages.not(lastVisiblePage).removeClass("post-item--last-page")
      // console.log("last visible page class removed. except from last:", lastVisiblePage.attr("id"), lastVisiblePage.data("title"))

      // if last visible page is the last page of the siblings = last subpage of a top page
      // and page height is less than window height

      if (lastVisiblePage.height() < window.innerHeight && siblings.length && lastVisiblePage.attr("id") == siblings[siblings.length - 1]) {
        lastVisiblePage.addClass("post-item--last-page")
      }
    }
  }

  getPageSiblingsID(pageID) {
    // get the id of the sibling pages in the same top level menu

    let index
    let siblings
    // find in which  of top level menu the page is in
    this.topLevelFlatMenu.every((elem, ind) => {
      const i = elem.indexOf(pageID)
      if (i >= 0) {
        index = ind
        return false
      }
      return true
    })

    if (index >= 0) {
      siblings = this.topLevelFlatMenu[index]
    } else {
      // id not found in menu -> page id is only sibling
      siblings = [pageID]
    }

    // console.log(pageID, "page siblings:", siblings)
    return siblings
  }

  // makeSiblingsString(siblings) {
  //   let sibString = ""
  //   if (Array.isArray(siblings) && siblings.length) {
  //     for (const sibling of siblings) {
  //       sibString = sibString + "#" + sibling + ", "
  //     }
  //   }
  //   sibString = sibString.slice(0, -2)
  //   // console.log("sib string", sibString)
  //   return sibString
  // }

  //
  // setPathForPageTitle(id) {
  //   const indexes = this.getMenuIndexesOfCurrentPage(id)
  //   if (indexes.index != null && indexes.index >= 0) {
  //     const postItemTitle = $(`#${id}`).children(".post-item-title")

  //     const title = postItemTitle.data("title")

  //     // adjust title of current page with top and sub page title
  //     const title1st = `${this.menuData[indexes.index].slug}`
  //     const title2nd = `${indexes.subIndex != null && indexes.subIndex >= 0 ? `${this.menuData[indexes.index].subMenus[indexes.subIndex].slug}` : ""}`
  //     const title3rd = `${indexes.subSubIndex != null && indexes.subSubIndex >= 0 ? `${this.menuData[indexes.index].subMenus[indexes.subIndex].subMenus[indexes.subSubIndex].slug}` : ""}`

  //     const pageID1st = `${this.menuData[indexes.index].pageID}`
  //     const pageID2nd = `${indexes.subIndex != null && indexes.subIndex >= 0 ? `${this.menuData[indexes.index].subMenus[indexes.subIndex].pageID}` : ""}`

  //     postItemTitle.html(`<h1 class="post-item-title__title">${title}</h1><div class="post-item-title__path">${title1st != "" && title2nd != "" ? `<span data-id="${pageID1st}">\u25BC&nbsp;${title1st}</span>` : ``}${title2nd != "" && title3rd != "" ? `<span data-id="${pageID2nd}">\u25BC&nbsp;${title2nd}</span>` : ``}</div>`)
  //     // console.log("path for title. indexes", title)
  //   }
  // }

  observePage(page) {
    const pageID = page.attr("id")
    // console.log("->->-> observePage. page:", pageID, page.data("title"))

    this.observeStickyTitle(page)
    this.observeStickyTitleEnter(page)
    // this.observePageEnter(page)
  }

  observePageEnter(page) {
    // console.log("observePageEnter:", page.attr("id"), page.data("title"))

    if (!this.pageEnterObserver) {
      this.pageEnterObserver = new window.IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              // console.log("page enter observer intersecting", entry.target.dataset.id)
              const pageID = entry.target.dataset.id

              entry.target.classList.add("post-item-container--in-view")
              // remove observer
              this.pageEnterObserver.unobserve(entry.target)
            }
          })
        },
        {
          root: null,
          rootMargin: `-${this.marginTop}px 0px 0px 0px`,
          threshold: 0 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }

    if (page[0]) this.pageEnterObserver.observe(page[0])
  }

  // getMenuIndexesOfCurrentPage(currentID) {
  //   let indexes = {
  //     index: null,
  //     subIndex: null,
  //     subSubIndex: null
  //   }

  //   for (let i = 0; i < this.menuData.length; i++) {
  //     // console.log(this.menuData[i])
  //     if (currentID === this.menuData[i].pageID) {
  //       // id found in menu
  //       indexes.index = i
  //       break
  //     }

  //     for (let j = 0; j < this.menuData[i].subMenus.length; j++) {
  //       // console.log(this.menuData[i].subMenus[j])
  //       if (currentID === this.menuData[i].subMenus[j].pageID) {
  //         // id found in sub menu
  //         indexes.index = i
  //         indexes.subIndex = j
  //         break
  //       }

  //       for (let k = 0; k < this.menuData[i].subMenus[j].subMenus.length; k++) {
  //         // console.log(this.menuData[i].subMenus[j].subMenus[k])
  //         if (currentID === this.menuData[i].subMenus[j].subMenus[k].pageID) {
  //           // id found in sub sub menu
  //           indexes.index = i
  //           indexes.subIndex = j
  //           indexes.subSubIndex = k
  //           break
  //         }
  //       }
  //     }
  //   }
  //   // console.log("menu indexes found", indexes)
  //   return indexes
  // }

  getPageIDFromMenuItemClasslist(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  observeStickyTitle(page) {
    // observe when the page title leaves the viewport (is not completely in the viewport anymore). threshold = 1
    // the viewport top margin is minus the height of the adminbar plus site header
    // bottom margin is 0
    //  margin top is 2 px lower than the content container
    // so when the title is at the top it's not in the viewport anymore and the title is sticky
    // on the bottom we ignore when the title leaves the viewport

    if (!this.stickyTitleObserver) {
      // let adminH = getComputedStyle(document.documentElement).getPropertyValue("--adminBarHeight")

      this.stickyTitleObserver = new IntersectionObserver(
        ([e]) => {
          const sticky = e.intersectionRatio < 1 // only sticky when not completely in the viewport (== 1)
          const visible = e.intersectionRect.y >= e.rootBounds.y - 4 && e.boundingClientRect.y < e.rootBounds.height // in viewport?
          const id = e.target.parentElement.id
          // console.log("sticky leave observer", e, sticky, visible, id)

          e.target.classList.toggle("is-sticky", e.intersectionRatio < 1 && e.boundingClientRect.y < e.rootBounds.height)
          if (sticky && visible && id) {
            // title is about to leave the page -> sticky, current page
            // this is the current Title
            // console.log("current title leaving the viewport -> is sticky", id, e)
            this.currentPageID(id) // callback
          }
        },
        { rootMargin: `-${this.marginTop + 3}px 0px 0px 0px`, threshold: [1] }
      )
      // console.log(this.stickyTitleObserver)
    }

    const title = page.children(".post-item-title")[0]
    // console.log("title", title)
    if (title) this.stickyTitleObserver.observe(title)
  }

  observeStickyTitleEnter(page) {
    // observe when the page title enters the viewport. threshold = 0.1

    if (!this.stickyTitleEnterObserver) {
      this.stickyTitleEnterObserver = new IntersectionObserver(
        ([e]) => {
          if (e.isIntersecting) {
            // console.log("title enter intersecting", e.target.parentElement.id, e, this.scrollDir)
            // exclude entering the viewport from below

            // if (e.intersectionRect.y >= e.rootBounds.y && e.intersectionRect.y < e.rootBounds.y + 200) {
            if (e.intersectionRect.y >= e.rootBounds.y - 1 && !this.scrollDir) {
              // title enters viewport from above
              const id = e.target.parentElement.id
              if (id) {
                // console.log("current title from title enter")
                this.currentPageID(id)
              }
            }
          }
        },
        { rootMargin: `-${this.marginTop}px 0px 0px 0px`, threshold: [0.1] }
      )
    }

    const title = page.children(".post-item-title")[0]
    if (title) this.stickyTitleEnterObserver.observe(title)
  }

  getMarginTop() {
    // margin top = adminbar height + header height
    let ah = 0
    const adminbar = document.querySelector("#wpadminbar")
    if (adminbar) ah = adminbar.offsetHeight // height including: padding and border, without margin
    let hh = 0
    if (this.mobileView) hh = this.mobileHeaderHeight
    else hh = document.querySelector(".site-header").offsetHeight
    // console.log("margin top for observer", ah + hh)
    return ah + hh
  }

  getAdminBarHeight() {
    let ah = 0
    const adminbar = document.querySelector("#wpadminbar")
    if (adminbar) ah = adminbar.offsetHeight // height including: padding and border, without margin
    return ah
  }

  getSiteHeaderHeight() {
    // header height = site header height
    let hh = 0
    if (this.mobileView) hh = this.mobileHeaderHeight
    else hh = document.querySelector(".site-header").offsetHeight
    return hh
  }

  scrollTo(page, time) {
    time = time ?? this.scrollTime
    return new Promise((resolve, reject) => {
      this.scrollToCounter = 0 // reset the scroll counter
      this.scrollFunc(page, time, resolve)
    })
  }

  scrollFunc(page, time, resolve) {
    const top = this.getElementTop(page)

    // console.log("->->->->---- scroll to page:", page.attr("id"), top, $("html").scrollTop(), window.scrollY)

    this.autoScrolling = true // indicate scrolling in progress
    this.scrollToCounter++

    $("html, body")
      .stop(true, true)
      .animate(
        {
          scrollTop: top
        },
        time,
        () => {
          // complete

          clearTimeout(this.autoScrollingTimeout)

          this.autoScrollingTimeout = setTimeout(() => {
            // check if position is correct
            // const top = this.getElementTop(page)
            // if (top != window.scrollY && this.scrollToCounter < 10) {
            //   // position is incorrect -> scroll again. limit to 10 scroll calls
            //   this.scrollFunc(page, time, resolve)
            // } else {
            this.autoScrolling = false
            resolve()
            // console.log("scrollTo finished", page.attr("id"), window.scrollY, top, $("html").scrollTop())
            // }
          }, 250)
        }
      )
  }

  getElementTop(page) {
    let top = 0
    if (this.mobileView) {
      const siteHeader = $(".site-header")
      if (siteHeader.hasClass("collapsed")) {
        top = Math.max(0, Math.round(page.offset().top - this.adminBarhHeight - this.mobileHeaderHeight))
      } else {
        top = Math.max(0, Math.round(page.offset().top - this.adminBarhHeight - siteHeader.outerHeight()))
      }
    } else top = Math.max(0, Math.round(page.position().top))

    return top
  }

  jumpTo(top) {
    return new Promise((resolve, reject) => {
      window.scrollTo(0, top)

      setTimeout(() => {
        window.scrollTo(0, top)
        resolve()
      }, 100)
      setTimeout(() => {
        window.scrollTo(0, top)
        resolve()
      }, 200)
    })
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   $(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default PageLoader

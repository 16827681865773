// import Lazyload from "lazyload"

class LoadMoreProjects {
  constructor(archivePageID, languageSlug) {
    this.root_url = localized?.root_url ?? ""
    // console.log("root url from module load more projects", this.root_url)
    this.lastPostObserver
    this.postEnterObserver
    this.postsLoading
    this.archivePageID = archivePageID ?? 0
    this.languageSlug = languageSlug ?? "de"
    this.archivePage = jQuery(`#${this.archivePageID}`)
    this.observeLastPost()
    this.loaded = false // callbackfunction when projekt loading is done
    this.loadMoreProjects()
  }

  events() {}

  loadMoreProjects() {
    if (!this.postsLoading && this.archivePage.length) {
      this.archivePageContent = this.archivePage.children(".project-archive__content")

      this.postsLoading = true
      const offset = jQuery(".project-archive-item").length // number of loaded projekt archive items
      const reqstring = `${localized.root_url}/wp-json/wp/v2/projekt?lang=${this.languageSlug}&per_page=${localized.posts_per_page}&offset=${offset}&orderby=projekt_nummer&order=desc`
      // console.log("getjson", reqstring)
      jQuery.getJSON(reqstring, posts => {
        // console.log(posts, offset)

        if (posts.length) {
          const newPosts = jQuery(`
          ${posts
            .map(post => {
              return `
          <a ${post.acf.projekt_anzeigen ? `href="${post.link}"` : ""} class="project-link project-archive-item project-archive-item--new-item post-type-projekt ${post.kategorien.join(" ")}" data-id="${post.acf.projekt_anzeigen ? post.id : 0}" data-title="${post.title.rendered}"  data-history="${post.slug}">
          
          <div class="project-archive-item__data ${post.acf.projekt_anzeigen ? `color--highlight` : ``}">
              <div class="project-archive-item__number">${post.acf.projekt_nummer ? post.acf.projekt_nummer : ""}</div>
              <div class="project-archive-item__info">${post.title.rendered ? `${post.title.rendered}<br>` : ""}${post.acf.projekt_art ? `${post.acf.projekt_art}<br>` : ``}${post.acf.projekt_standort ? `${post.acf.projekt_standort}<br>` : ``}${post.acf.projekt_planung_ausfuehrung != "" ? post.acf.projekt_planung_ausfuehrung : post.acf.projekt_wettbewerb_jahr}</div>
          </div>

          ${
            post.images_thumbnail || post.featured_image
              ? `<div class="swiper">
            <div class="swiper-wrapper">

            ${
              post.images_thumbnail
                ? post.images_thumbnail
                    .map(
                      img => `
                        <div class="swiper-slide project-archive-item__image ${img.terms}">
                          <img class="swiper-lazy" 
                          src="${img.url}" 
                          srcset="${img.srcset}" 
                          width="${img.width}"
                          height="${img.height}"
                          sizes="${img.width}px"
                          alt="${img.alt}">
                        </div>
          `
                    )
                    .join("")
                : `${
                    post.featured_image
                      ? `<div class="swiper-slide project-archive-item__image ${post.featured_image.terms}">
                          <img class="swiper-lazy" 
                          src="${post.featured_image.url}" 
                          srcset="${post.featured_image.srcset}" 
                          width="${post.featured_image.width}"
                          height="${post.featured_image.height}"
                          sizes="${post.featured_image.width}px"
                          alt="${post.featured_image.alt}">
                          </div>`
                      : ``
                  }`
            }

            </div>
          </div>`
              : ``
          }

  
        </a>
          `
            })
            .join("")}
        `).appendTo(this.archivePageContent)

          newPosts.filter(".project-archive-item").each((index, post) => {
            this.observePostEnter(post)
            // console.log("observe post", post)
          })

          // this.observeLastPost()
          this.postsLoading = false
          this.loaded(true)
          this.loadMoreProjects()
        } else {
          // no more posts
          // this.lastPostObserver.disconnect()
          // this.loaded(true)
        }
      })
    }
  }

  observeLastPost() {
    if (this.lastPostObserver) {
      this.lastPostObserver.disconnect()
    } else {
      this.lastPostObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("Last Projekt ENTER")
            this.loadMoreProjects()
          }
        },
        {
          root: null,
          rootMargin: "100px 0px 0px 0px",
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    const lastPost = document.querySelector(".project-archive-item:last-child")

    lastPost ? this.lastPostObserver.observe(lastPost) : ""
  }

  observePostEnter(post) {
    if (!this.postEnterObserver) {
      this.postEnterObserver = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("Post ENTER", entry.target)
            entry.target.classList.add("project-archive-item--in-view")
            this.postEnterObserver.unobserve(entry.target)
          }
        },
        {
          root: null,
          threshold: 0 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    // console.log("observer post. top is", post.getBoundingClientRect().top, window.innerHeight)

    // add the in view class, if the loaded post is already in the viewport (intersection observer not triggering correctly)
    if (post.getBoundingClientRect().top <= window.innerHeight + 200) {
      post.classList.add("project-archive-item--in-view")
    } else {
      this.postEnterObserver.observe(post)
    }
  }

  addLazyloadToElement(elem) {
    // https://github.com/tuupola/lazyload
    // $("img.lazyload").lazyload();
    // lazyload();

    // const elem = document.querySelector(`[id="${elemID}"]`);
    // const elem = $elem[0]
    const images = elem.querySelectorAll("img.lazyload")

    // console.log("lazyload images", images, " in container ", $elem);

    new Lazyload(images, {
      root: null,
      rootMargin: "50px",
      threshold: 0
    })

    // add load event listener for lazyload images
    // $elem.find(`img.lazyload`).on("load", function () {
    //   // console.log("img on elem xx loaded", $elem.attr('id'));
    //   jQuery(this).addClass("loaded") // add loaded class to image -> fade in with css opacity 1
    // })

    images.forEach(image => {
      image.addEventListener("load", () => {
        image.classList.add("loaded")
      })
    })
  }
}

export default LoadMoreProjects

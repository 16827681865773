// import style index file
import "./styles/index.scss"

import Swiper from "swiper/bundle"

// Our modules / classes
import LoadMorePosts from "./scripts/modules/LoadMorePosts"
import LoadMoreProjects from "./scripts/modules/LoadMoreProjects"
import PageLoader from "./scripts/modules/PageLoader"
import ProjektLoader from "./scripts/modules/ProjektLoader"
// import Search from "./scripts/modules/Search"

let loadMorePosts
let loadMoreProjects
let pageLoader
let projektLoader
let projektArchiveFilters = []
let projektArchiveSlideFilter = ""
let projektArchiveSlideFilterPrev = ""

var debug = true
const mobileBreakpoint = 782
let mobileView = false

const ScrollDelta = 10 // how many pixels to scroll to call scroll dependent function

let adminBarHeight = 0
// let siteTitleHeight = 0
let siteHeaderHeight = 0
// let siteHeaderTimeout // used for collapsing and expanding the header
// let siteHeaderCollapsed = false

let navVisible = false
let menuChanging = false // when the menu in the site header is shown / hidden
let menuChangeTimeout

let historyUpdate = true // to push to history or not
let orientationLandscape = true

let lastScroll = 0
let prevScrollDelta = 0
let scrollYPositions = {}
let scrollTopTimeout

let currentContentID = -1
let prevContentID = -1

const menuAnimationTime = 400
let autoScrolling = false
let autoScrollingTimeout

let requestAllPartsInterval = null
let nowWithKeyboard = false // if true mobile keyboard is showing
let observer
let touchsupport
let deltaY = 0
let prevY = 0
let requestedPageID = -1 // when clicking on a menu item for a page. the page id we want to load
let requestedPageIDTimeout

let requestedProjektID = -1 // when clicking on a menu item for a page. the page id we want to load
let requestedProjektIDTimeout
let scrollPositionToRestore = 0 // when open / close extended menu scroll position needs to be restored

let supportsPassive = false // for scroll event listeners

jQuery(function ($) {
  // if (debug) console.log("document ready!")
  const $html = $("html")
  $html.addClass("js") // js is working
  // console.log("root url:", localized.root_url)
  let firstLoad = true // indicate page loaded

  if (checkFlexGap()) {
    document.documentElement.classList.add("flexbox-gap")
  } else {
    document.documentElement.classList.add("no-flexbox-gap")
  }

  checkSupportsPassive()

  // console.log("nonce", localized.nonce, localized.nonce_field);
  if ("scrollRestoration" in history) {
    history.scrollRestoration = "manual"
    // history.scrollRestoration = "auto"
  }
  // for ios touch -> enable active state for links
  document.addEventListener(
    "touchstart",
    function () {
      // console.log("touchstart")
    },
    supportsPassive ? { passive: true } : false
  )

  let pathname = window.location.pathname

  // console.log('pathname', pathname);
  // console.log('history state', history.state);

  const blogName = document.querySelector('meta[name="name"]').content
  const blogDescription = document.querySelector('meta[name="description"]').content

  // console.log(blogName, blogDescription);

  // -----------------------------------------------

  const siteContainer = $(".site-container")

  const siteHeader = $(".site-header")
  const siteHeaderDescription = $(".site-header__description") // schweizerische gesellschaft....
  const siteHeaderLanguageButton = $(".language-icon")
  const languageSwitcher = $(".extended-menu__language-switcher")
  const additionalMenu = $(".extended-menu__additional-menu")
  const openCloseMenu = $(".nav-icon")
  const downloadPageButton = $(".download-icon")
  let extMenuOpen = false

  const siteTitle = $(".site-header__title")
  const siteTitleLink = $(".site-header__link")

  const siteMenu = $(".site-menu") // container for the main menu and download menu

  const menuItemHome = $(".menu-item.menu-item-home")
  const allMenuItems = $(".menu-item") // all menu items
  const allMenuLinks = $(".menu-item:not(.lang-item) > a") // all menu item links

  // menu-download
  const downloadMenu = $(".menu-download")
  const downloadMenuItems = $(".menu-download .menu-item")

  // menu-faq
  const faqMenu = $(".menu-faq")
  const faqMenuItems = $(".menu-faq .menu-item")

  // menu-additional
  const additionalMenuItems = $(".menu-additional .menu-item")

  // menu-main = menu nav
  const menuMain = $("ul.menu-main")
  const menuItems = $(".menu-main .menu-item") // all menu items in main menu
  const menuNavLinks = $(".menu-main > li.menu-item > a") // top level nav links
  const menuNavSubLinks = $(".menu-main > .menu-item > .sub-menu > li.menu-item > a") // 2. level nav links
  const menuNavSubSubLinks = $(".menu-main > .menu-item > .sub-menu > .menu-item > .sub-menu > li.menu-item > a") // 3. level nav links

  const menuNavItemsLevel1 = $(".menu-main > li.menu-item") // top level nav menu items
  const menuNavItemsLevel2 = $(".menu-main > .menu-item > .sub-menu > li.menu-item") // 2. level nav links
  const menuNavItemsLevel3 = $(".menu-main > .menu-item > .sub-menu > .menu-item > .sub-menu > li.menu-item") // 3. level nav links

  const menuDownloadLinks = $("ul.menu-download > li.menu-item > a")
  const menuDownloadItemsLevel1 = $("ul.menu-download > li.menu-item") // top level download menu links (only 1 == downloads)
  const menuDownloadItemsLevel2 = $("ul.menu-download > .menu-item > .sub-menu > li.menu-item") // 2. level Download links
  const menuDownloadItemsLevel3 = $("ul.menu-download > .menu-item > .sub-menu > .menu-item > .sub-menu > li.menu-item") // 3. level Download links

  const menuFaqItemsLevel1 = $(".menu-faq > li.menu-item") // top level faq menu items

  const extendedMenu = $(".extended-menu")
  const extendedMenuItems = $(".extended-menu .menu-item")

  // const siteMenuAndContent = $(".site-menu-content-container-wrapper")
  const contentContainer = $(".content-container")

  const kontaktMenuEmail = $(".kontakt-menu .email-plain-mail")

  // -----------------------------------------------------------------------------------------------------

  const $body = $("body")
  const languageSlug = $body.data("language-slug")
  // console.log("lang slug", languageSlug)
  const $mainMenu = $(".menu-main")
  const homeID = $body.data("home-id").toString() // post id of the home page as string

  setLandscape()
  touchSupport()

  // set background color from customizer
  document.documentElement.style.setProperty("--bgcolor", $("body").data("bgcolor"))
  // set highlight color from customizer
  document.documentElement.style.setProperty("--highlight-color", $("body").data("highlightcolor"))

  calcVhPropertyFromClientHeight() // for mobile viewport height on ios
  setAdminBarHeight()
  headerHeight()
  checkMobileView()

  // const search = new Search()
  // search.closeExtMenu = function () {
  //   closeExtendedMenu()
  // }

  closeExtendedMenu()

  // build menu data
  // const menuData = buildMenuData()

  // restructureMainMenu() // separate the menu in two divs for the extended menu

  // hide and fade in nav and content elements with visibility hidden
  // siteHeader.hide()
  // siteHeader.css("visibility", "visible")
  // siteHeader.show()

  // siteContainer.hide()
  siteContainer.css("visibility", "visible")
  // siteContainer.fadeIn(400)

  setTimeout(() => {
    // safety
    headerHeight()
  }, 100)

  const postsContainer = $(".posts-container")
  let postItemContainers = $(".post-item-container")

  // menu and title navigation ------------------------------------------------------------------------------------------------

  // allMenuLinks.on("click", function (e) {
  //   const menuNavItem = $(this).parent()
  //   const isPage = menuNavItem.hasClass("menu-item-object-page")
  //   const isHome = menuNavItem.hasClass("menu-item-home")
  //   const id = getObjectIDFromMenuItem(menuNavItem)
  //   const topLevelItem = menuNavItem.hasClass("menu-item-parent-id-0")
  //   const isMenuMain = menuItems.is(menuNavItem)
  //   const isCurrentMenuItem = menuNavItem.hasClass("current-menu-item")
  //   //const subMenuIsVisible = menuNavItem.hasClass("sub-menu-visible")

  //   // console.log("Menu Item link clicked", id, topLevelItem, isMenuMain)

  //   if (mobileView && topLevelItem && isMenuMain && !isCurrentMenuItem && !subMenuIsVisible) {
  //     // just open the menu if mobile and ext menu and top level menu item
  //     e.preventDefault()
  //     setCurrentMenuItem(menuNavItem, true) // set menu item with slide up down
  //   } else {
  //     if (extMenuOpen) closeExtendedMenu()

  //     const slug = getSlugFromURL($(this).attr("href"))

  //     // special case: in the menu-additional there are 2 links we want to handle separately.
  //     // faq and downloads
  //     if ((slug === "faq/" || slug === "downloads/") && $(this).parent().parent().hasClass("menu-additional")) {
  //       // console.log("is faq or download page")
  //     }

  //     if (isPage || isHome) {
  //       // links in the menu are either pages or home = blog page
  //       e.preventDefault()

  //       updateHistory($(this).attr("href"), $(this).text())
  //       // if different url -> open the page
  //       if (isPage) {
  //         openPageLink($(this)) // and set the current menu item
  //       } else {
  //         openBlogPage()
  //       }
  //     }
  //   }
  // })

  // .nav-icon click handler
  openCloseMenu.on("click", function () {
    if (openCloseMenu.hasClass("checked")) {
      closeExtendedMenu(true)
    } else {
      openExtendedMenu()
    }
  })

  // menu hadling --------------------------------------------

  // open the extended menu
  function openExtendedMenu() {
    // storeScrollPosition()
    menuChanging = true
    // console.log("open extended menu", window.scrollY)
    // scrollPositionToRestore = window.scrollY
    extMenuOpen = true
    // collapseHeader()
    $html.addClass("extended-menu-open")
    openCloseMenu.addClass("checked")

    // scroll to top
    // setTimeout(() => {
    //   extendedMenu.fadeIn()
    //   $("html, body")
    //     .stop(true, true)
    //     .animate(
    //       {
    //         scrollTop: 0
    //       },
    //       100,
    //       () => {
    //         extendedMenu.fadeIn()
    //       }
    //     )
    // }, 100)

    setTimeout(() => {
      headerHeight()
      menuChanging = false
    }, 410)
  }

  function closeExtendedMenu(restoreY) {
    menuChanging = true
    extMenuOpen = false
    openCloseMenu.removeClass("checked")
    $html.removeClass("extended-menu-open")

    clearTimeout(menuChangeTimeout)
    menuChangeTimeout = setTimeout(() => {
      headerHeight()
      // restore scrollposition
      if (restoreY) {
        // console.log("restore scroll position", scrollPositionToRestore)
        // window.scrollTo(0, scrollPositionToRestore)
        restoreScrollPosition(0)
      }
      menuChanging = false
    }, 410)
    // console.log("close extended menu", window.scrollY)
  }

  function showNav() {
    if (!navVisible) {
      navVisible = true
      $html.addClass("site-header--visible")
      siteHeader.addClass("show")
      // console.log("\\\\\\ show nav")
    }
  }
  function hideNav() {
    if (navVisible) {
      navVisible = false
      $html.removeClass("site-header--visible")
      siteHeader.removeClass("show")
      // console.log("///// hide nav")
    }
  }

  // scrollposition handling
  function storeScrollPosition() {
    scrollYPositions[currentContentID] = window.scrollY
    // console.log(scrollYPositions)
  }
  function restoreScrollPosition(ms) {
    const top = scrollYPositions[currentContentID] ?? 0
    // console.log("restore scroll pos", scrollYPositions[currentContentID], currentContentID)
    const time = ms ?? 400
    if (ms > 0) {
      scrollTop(top, time)
    } else {
      autoScrolling = true
      window.scrollTo(0, top)
      autoScrolling = false
    }
  }

  function scrollTop(top, time) {
    top = top || 0
    time = time ?? 0
    autoScrolling = true
    // console.log("scrolltop", top)
    $("html, body")
      .add(contentContainer)
      .animate(
        {
          scrollTop: top
        },
        time,
        function () {
          clearTimeout(scrollTopTimeout)
          scrollTopTimeout = setTimeout(() => {
            autoScrolling = false
          }, 100)
        }
      )
  }

  // content loading --------------------------------------------------------------------------------------------------------------------------------------------------------
  function openPageLink($link, template) {
    // console.log("open page link", $link.attr("href"))

    const menuItem = $link.parent() // menu item of link

    const pageID = getObjectIDFromMenuItem(menuItem)

    // setCurrentMenuItem(menuItem)

    openPageWithID(pageID, template)
  }

  function setCurrentMenuItem(menuItem, slide) {
    // console.log("set current menu item", menuItem.children("a").text())
    // works with main, download and faq menu
    // this function takes a menu item and decides which level it is on (main level, 1st sublevel, 2nd sublevel )
    // and sets it as the current active menu item
    // and open closes the menu structure accordingly

    const isHome = menuItem.hasClass("menu-item-home")
    const hasChildren = menuItem.hasClass("menu-item-has-children")
    const parentSubmenu = menuItem.parent(".sub-menu")
    const isSubmenuItem = parentSubmenu.length // menu item is in a submenu
    let isSubSubmenuItem = false

    let isDownloadMenuItem = downloadMenuItems.filter(menuItem).length > 0
    let isFaqMenuItem = faqMenuItems.filter(menuItem).length > 0
    const isAdditionalMenuItem = additionalMenuItems.filter(menuItem).length // menu item is in the additional menu

    let menuMainSubMenuToShow // the top submenu of the main nav the current menu item is in, if it's in the main nav and should be shown
    let menuMainTopMenuItemToShow // the top menu item the current menu item is part of, if it's in the main nav menu

    if (isAdditionalMenuItem) {
      // -> check if it is download or faq item in the additional menu
      const slug = getSlugFromURL(menuItem.children("a").attr("href"))
      if (slug === "faq/") isFaqMenuItem = true
      if (slug === "downloads/") isDownloadMenuItem = true
    }

    let menuLevel = -1 // set to invalid value

    // determine menu level: 0 = top level, 1 = sub menu, 2 = sub sub menu
    if (isSubmenuItem) {
      // is submenu -> check if it is a sub sub menu item
      isSubSubmenuItem = parentSubmenu.parent().parent(".sub-menu").length > 0
    }

    if (!isSubmenuItem) menuLevel = 0
    else if (isSubmenuItem && !isSubSubmenuItem) menuLevel = 1
    else if (isSubSubmenuItem) menuLevel = 2

    // get the top level submenu according the level of the menu item
    switch (menuLevel) {
      //
      case 0: {
        // top level menu item
        // console.log("Set menu item: Top Level Menu Item")

        const submenu = menuItem.children(".sub-menu")
        menuMainSubMenuToShow = submenu
        menuMainTopMenuItemToShow = menuItem // menu item is top level menu item
        break
      }

      case 1: {
        // console.log("Set menu item: 1st Submenu Item -----------------")

        menuMainSubMenuToShow = parentSubmenu
        menuMainTopMenuItemToShow = parentSubmenu.parent() // top level menu item
        break
      }

      case 2: {
        // console.log("Set menu item: 2nd Submenu level")

        // check if parent submenu is also a sub menu
        const grandParentSubmenu = parentSubmenu.parent().parent(".sub-menu")
        if (grandParentSubmenu.length) {
          menuMainSubMenuToShow = grandParentSubmenu
          menuMainTopMenuItemToShow = grandParentSubmenu.parent() // top level menu item
        }
        break
      }
    }

    allMenuItems.removeClass("current-menu-item") // remove current menu from all menu items
    menuItem.addClass("current-menu-item") // new current menu item

    // show / hide download and faq menu accordingly
    if (isDownloadMenuItem) {
      // console.log("is download menu item")
      if (isAdditionalMenuItem) {
        // download menu in the additional menu was selected -> select the download menu item in the download menu
        const id = getObjectIDFromMenuItem(menuItem)
        downloadMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      } else {
        // item in download menu -> add current menu to download menu item in the additional menu
        const id = getObjectIDFromMenuItem(menuMainTopMenuItemToShow) // get page id from top level download menu item
        additionalMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      }
    } else if (isFaqMenuItem) {
      // console.log("is faq menu item")

      if (isAdditionalMenuItem) {
        // download menu in the additional menu was selected -> select the download menu item in the download menu
        const id = getObjectIDFromMenuItem(menuItem)
        faqMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      } else {
        // item in download menu -> add current menu to download menu item in the additional menu
        const id = getObjectIDFromMenuItem(menuMainTopMenuItemToShow) // get page id from top level download menu item
        additionalMenuItems.filter(`.wpse-object-id-${id}`).addClass("current-menu-item")
      }
      //  menuNavItemsLevel1.removeClass("sub-menu-visible")
      if (mobileView && !slide) {
        downloadMenu.stop().hide()
        faqMenu.stop().show(0, () => headerHeight())
      } else {
        downloadMenu.stop().slideUp(menuAnimationTime, "linear", () => headerHeight())
        faqMenu.stop().slideDown(menuAnimationTime, "linear", () => headerHeight())
      }
    } else {
      // nav menu or home (or additional menu)
      // console.log("is nav menu item or home")

      if (isHome) {
        // menuNavItemsLevel1.removeClass("sub-menu-visible") // remove class from top level siblings
      } else {
        menuNavItemsLevel1.not(menuMainTopMenuItemToShow).removeClass("sub-menu-visible") // remove class from top level siblings
        // menuMainTopMenuItemToShow.addClass("sub-menu-visible")
      }

      if (menuMainSubMenuToShow) {
        if (mobileView && !slide) {
          if (menuMainSubMenuToShow.is(":hidden")) menuMainSubMenuToShow.stop().show(0, () => headerHeight())
        } else {
          if (menuMainSubMenuToShow.is(":hidden")) menuMainSubMenuToShow.stop().slideDown(menuAnimationTime, "linear", () => headerHeight())
        }
      } else {
        // no submenu to show -> slide up all submenus
      }
      if (mobileView && !slide) {
        faqMenu.stop().hide()
        downloadMenu.stop().hide(0, () => headerHeight())
      } else {
        faqMenu.stop().slideUp(menuAnimationTime, "linear", () => headerHeight())
        downloadMenu.stop().slideUp(menuAnimationTime, "linear", () => headerHeight())
      }
    }

    return menuLevel
  }

  function openPageWithID(pageID, template) {
    $body.removeClass("single-projekt")

    // pageID is typeof string, not number!!!
    // console.log("open page with id:", pageID, typeof pageID)

    if (pageID) {
      requestedPageID = pageID
      clearTimeout(requestedPageIDTimeout)
      requestedPageIDTimeout = setTimeout(() => {
        requestedPageID = -1
      }, 500)

      if (!firstLoad) {
        hideNav()
      } else {
        firstLoad = false
      }

      if (extMenuOpen) closeExtendedMenu()
      // if (search.searchActive) search.closeSearch()

      setCurrentContentID(pageID)

      updateLanguageSwitcher()

      if (!pageLoader) {
        // page loader not yet created. first time after page load
        pageLoader = new PageLoader(pageID, template) // hand menu data into pageloader

        // callback function when page id changes ----------------------------------------------
        pageLoader.currentPageID = id => {
          // console.log("current page id callback", id, $(`#${id}`).data("title"))
          setCurrentContentID(id)
          showTitlePath(id)

          const currMenuItem = $(`.wpse-object-id-${id}`) // find menu item referencing this page id

          if (currMenuItem.length && requestedPageID === -1) {
            // page referencing menu item found and we didn't request this same page by clicking on the menu
            // setCurrentMenuItem(currMenuItem)
            const currMenuItemLink = currMenuItem.children("a")
            if (historyUpdate && updateHistory(currMenuItemLink.attr("href"), currMenuItemLink.text())) updateLanguageSwitcher()
          }
        }
      } else {
        historyUpdate = false // don't update history during page load
        // load or show the requested page
        pageLoader
          .loadPage(pageID, template) // load / show page with pageID and scroll to the page
          .then(() => {
            // console.log("index.js page loaded", pageID)
            restoreScrollPosition(0)
            historyUpdate = true
            // showTitlePath(pageID)
          })
          .catch(err => console.log("page load error", err))
      }
    }
  }

  function openProjektByID(projektID, initialSlide) {
    // console.log("open single projekt by id:", projektID, typeof projektID)

    if (projektID) {
      requestedProjektID = projektID
      clearTimeout(requestedProjektIDTimeout)
      requestedProjektIDTimeout = setTimeout(() => {
        requestedProjektID = -1
      }, 500)

      setCurrentContentID(projektID)

      updateLanguageSwitcher()

      if (!projektLoader) {
        // console.log("no projekt loader")
        // projekt loader not yet created. first time after projekt load
        projektLoader = new ProjektLoader(projektID, initialSlide)
      } else {
        // console.log("projekt exists. load projekt..")
        historyUpdate = false // don't update history during projekt load
        // load or show the requested projekt
        projektLoader
          .loadProjekt(projektID, initialSlide) // load / show projekt with projektID and scroll to the projekt
          .then(() => {
            // console.log("index.js projekt loaded", projektID)
            historyUpdate = true
            showTitlePath(projektID)
          })
          .catch(err => console.log("projekt load error", err))
      }
    }
  }

  function openProjectArchive(slug) {
    // console.log("open the projekt archive", slug)
    $body.removeClass("single-projekt")

    // // adjust menu items if slug is in the menu
    // let kat = ""
    // let menuText = ""
    // if (slug) {
    //   const menuitem = getMenuItemBySlug(slug)
    //   if (menuitem) {
    //     setCurrentMenuItem(menuitem)
    //     menuText = menuitem.children("a").text()
    //     // console.log("current menu item: ", menuText)
    //   }
    //   if (slug.includes("arbeiten/")) kat = slug.replace("arbeiten/", "")

    //   if (kat !== "") kat = kat.replaceAll("/", "")
    // }

    // console.log("kat", kat)

    contentContainer.fadeIn()
    // hide all other page and post items
    let projectArchive = $("#project-archive")

    $(".post-item-container").not(projectArchive).hide()

    setCurrentContentID("project-archive")

    if (projectArchive.length == 0) {
      // no archive yet -> load all arbeiten
      // console.log("no project archive yet", window.location)
      // navigate to
      window.location.replace(window.location.href)
    } else {
      // console.log("project archive exists")
      const projects = projectArchive.find(".post-item-content__project")
      projectArchive.hide()

      if (!projectArchive.hasClass("initialized")) {
        // archive loaded by archive.php -> create projekt loader and init swiper

        projectArchive.fadeIn()

        loadMoreProjects = new LoadMoreProjects("project-archive", languageSlug)
        loadMoreProjects.loaded = loaded => {
          // console.log("more projects loaded")
          // initSwiper(projectArchive)
          filterProjektArchiveItems(projektArchiveFilters, projektArchiveSlideFilter, true)
        }

        initSwiper(projectArchive)
        projectArchive.addClass("initialized")
        // showHideProjects(projects, kat)
        // })
      } else {
        // showHideProjects(projects, kat)
        projectArchive.fadeIn()
        restoreScrollPosition()
      }
    }
  }

  function initSwiper(content) {
    const swipers = content.find(".swiper:not(.swiper-initialized)")
    if (swipers.length) {
      swipers.each(function () {
        // console.log("create project swiper")
        const swiper = new Swiper($(this)[0], {
          // Disable preloading of all images
          preloadImages: false,
          // Enable lazy loading
          lazy: {
            checkInView: true
          },
          watchSlidesProgress: true,
          slidesPerView: "auto",
          freeMode: true,
          spaceBetween: 10,
          loop: true,
          mousewheel: {
            forceToAxis: true
          },
          ...shouldBeEnabled($(this)[0])
        })
      })
    }
  }

  function shouldBeEnabled(carousel) {
    // const slidesCount = carousel.querySelectorAll(".swiper-slide").length
    // if (slidesCount) {
    let settings
    const wrapperWidth = carousel.querySelector(".swiper-wrapper").offsetWidth
    // console.log(slidesCount, wrapperWidth)
    const slidesWidth = [...carousel.querySelectorAll(".swiper-slide")].map(el => el.offsetWidth).reduce((total, width) => total + width)
    if (slidesWidth < wrapperWidth) {
      settings = {
        loop: false,
        // slidesPerView: slidesCount,
        enabled: false
      }
      // console.log(settings)
      return settings
    }
    // }
    return null
  }

  function showTitlePath(id) {
    if (mobileView) {
      // console.log("show path for id:", id)
      const postItemTitlePath = $(`#${id}`).find(".post-item-title__path")

      if (postItemTitlePath.length) {
        // reset all title paths except the current one
        $(".post-item-title__path").not(postItemTitlePath).stop().fadeOut()

        postItemTitlePath.stop().fadeIn().css("display", "flex")
        // console.log("fade in path", postItemTitlePath.siblings("h1").text(), id, postItemTitlePath.text())
      }
    }
  }

  function updateHistory(url, text) {
    // console.log("call update history", url, text)
    // history only gets updated when clicking on a different menu item, and not when navigating by url (goToWindowLocation)
    // and only if the url differs from the current one

    if (window.location.href !== url) {
      window.history.pushState(null, null, url)

      const slug = getSlugFromURL(url)

      // update the title tag when menu click. page load sets the title tag automatically (theme support 'title-tag')
      if (slug !== "") {
        // not home -> page | blogname
        document.title = (text ? text : slug) + " | " + blogName
        // console.log("history: not home ", url, slug, blogName)
      } else {
        // is home -> blogname | blogdescription
        // console.log("history: is home ", url, slug, blogName)
        document.title = blogName + " | " + blogDescription
      }
      // console.log("updated history ", url, slug)
      // update canonical link
      $('link[rel="canonical"]').attr("href", url)
      return true
    } else {
      // console.log("history not updated")
      return false
    }
  }

  function getSlugFromURL(url) {
    let slug = ""
    if (url != "") {
      let slug = url.split(`${window.location.origin}/${languageSlug != "de" ? `${languageSlug}/` : ``}`)[1]
      // if (slug.charAt(0) === "/") slug = slug.slice(1)
    }
    return slug
  }

  function updateLanguageSwitcher() {
    // console.log("---- update language switcher", currentContentID)

    if (currentContentID == "blog-page") {
      languageSwitcher.children("a").each(function () {
        // blog page is home page -> reset the language link to the languages home pages
        const langSlug = $(this).data("slug")
        const ref = `${window.location.origin}/${langSlug == "de" ? "" : langSlug}`
        $(this).attr("href", ref)
        $(this).removeClass("language-disabled")
        // update alternate links
        $(`link[rel="alternate"][hreflang="${langSlug}"]`).attr("href", ref)
      })
    } else {
      // for other posts get the links of the translated posts
      jQuery.getJSON(localized.root_url + "/wp-json/translatedpostids/v1/get?id=" + currentContentID, transData => {
        // console.log("translation data:", transData)

        // loop all language switch items -> de, fr, it, en
        languageSwitcher.children("a").each(function () {
          const langSlug = $(this).data("slug")

          const found = transData.find(obj => {
            return obj.lang === langSlug
          })
          if (found) {
            // translated post found -> use permalink of trans post
            $(this).attr("href", found.href)
            $(this).removeClass("language-disabled")
            // update alternate links
            $(`link[rel="alternate"][hreflang="${langSlug}"]`).attr("href", found.href)
          } else {
            // else use home page url
            const ref = `${window.location.origin}/${langSlug == "de" ? "" : langSlug}`
            $(this).attr("href", ref)
            // else disable the language
            $(this).addClass("language-disabled")
            // update alternate links
            $(`link[rel="alternate"][hreflang="${langSlug}"]`).attr("href", ref)
          }
        })
      })
    }
  }

  // Page item event handlers -------------------------------------
  //
  // project links on home page and projekt archive page -> open single project page
  //
  $(document).on("click", ".project-link", function (e) {
    // console.log("project link clicked")
    e.preventDefault()

    const projectID = $(this).data("id")
    if (projectID > 0) {
      // if link to a project -> open the project
      scrollPositionToRestore = window.scrollY
      // update history
      updateHistory($(this).attr("href"), $(this).data("title"))
      openProjektByID(projectID)
    }
    // console.log("click link project item", projectID)
  })

  // test archive projekt projekt image in swiper click -> open projekt & slide to index
  $(document).on("click", ".project-link .swiper-slide", function (e) {
    e.preventDefault()
    // e.stopImmediatePropagation()
    e.stopPropagation()
    let label = $(this).attr("aria-label") // e.g. "2 / 2"
    const splits = label.split("/")
    if (splits) {
      label = parseInt(splits[0])
      if (!isNaN(label) && label >= 1) {
        const slideNbr = label - 1
        const projectLink = $(this).parents(".project-link")
        const href = projectLink.attr("href")
        if (href && href != "") {
          updateHistory(href, projectLink.data("title"))
          openProjektByID(projectLink.data("id"), slideNbr)
        }
      }
    }
    // console.log("slide clicked", label)
  })

  // close single projekt button
  $(document).on("click", ".single-projekt-item__close-button", function (e) {
    // console.log("close single project")
    // check if home page or projekt archive page is loaded
    const pArchive = $("#project-archive")
    const home = $(`#${homeID}`)
    // if archive or home page already loaded -> back to them
    if (pArchive.length || home.length) {
      e.preventDefault()

      // get the current post item container
      $(this).parents(".post-item-container").fadeOut()
      $body.removeClass("single-projekt")
      if (pArchive.length) {
        // project archive exists -> show it
        updateHistory(pArchive.data("href"), pArchive.data("title"))
        // pArchive.fadeIn()
        // window.scrollTo(0, scrollPositionToRestore) // restore scroll position for archive
        openProjectArchive()
      } else {
        // home exists
        updateHistory(home.data("href"), home.data("title"))
        // home.fadeIn()
        openPageWithID(homeID)
      }
    }
  })

  // archive projekte cat filter event handlers -------------------------------------
  // $(document).on("click", ".archive-filter__title", function (e) {
  //   if (touchsupport) {
  //     // console.log("touch click filter title")
  //   }
  // })

  $(document).on("click", ".archive-filter__item", function (e) {
    e.preventDefault()
    const filter = $(this).parents(".archive-filter")
    const listItems = filter.find(".archive-filter__item")
    // const filter = $(this).attr("data-filter")
    // console.log($(this).parents(".archive-filter").length)

    // set current filter item
    listItems.removeClass("archive-filter__item--active")
    $(this).addClass("archive-filter__item--active")

    // indicate if there is a filter active in the title
    if ($(this).hasClass("no-filter")) {
      filter.removeClass("archive-filter--active")
    } else {
      filter.addClass("archive-filter--active")
    }

    const activeFilters = [] // active archive filters array
    let activeSlideFilter = "" // active archive slider filter -> filtering the slides of a projekt

    $(".archive-filter__item--active").each(function (index) {
      // get all active filter items
      const filter = $(this).attr("data-filter") // get filter string, alle kategorien == ""
      if (filter !== "") {
        if ($(this).hasClass("archive-filter__item--slide-filter")) {
          // filter item to filter the slides of a projekt
          activeSlideFilter = filter
        } else {
          // filter item to filter the projekts
          activeFilters.push(filter)
        }
      }
    })

    projektArchiveFilters = activeFilters
    projektArchiveSlideFilter = activeSlideFilter

    filterProjektArchiveItems(projektArchiveFilters, projektArchiveSlideFilter)
    // console.log(projektArchiveFilters, projektArchiveSlideFilter)
  })

  $(document).on("click", ".archive-view-select__item", function (e) {
    const list = $(this).parent()
    list.children(".archive-view-select__item").removeClass("archive-view-select__item--active")
    $(this).addClass("archive-view-select__item--active")
    const archive = $(this).parents("#project-archive")
    if ($(this).hasClass("archive-view-select--list-view")) {
      // list view is selected
      archive.addClass("project-archive--list-view")
      setCurrentContentID("project-archive--list-view")
      restoreScrollPosition(0)
    } else {
      // back to project archive
      archive.removeClass("project-archive--list-view")
      setCurrentContentID("project-archive")
      restoreScrollPosition(0)
    }
  })

  function filterProjektArchiveItems(filters, slideFilter, newItemsOnly) {
    // filter the projekts (filters) and the slides of the projekts (slideFilter)
    let projectItems
    if (newItemsOnly) {
      projectItems = $(".project-archive  .project-archive-item--new-item")
    } else {
      projectItems = $(".project-archive  .project-archive-item")
    }

    // show hide projekts
    if (filters.length) {
      let filterStringOr = ""
      let filterStringAnd = ""

      filters.forEach(function (filter) {
        if (filter !== "") {
          filterStringOr = filterStringOr + (filterStringOr == "" ? "." : ", .") + filter
          filterStringAnd = filterStringAnd + "." + filter
        }
      })

      // console.log("or", filterStringOr, "and", filterStringAnd)

      if (filterStringAnd !== "") {
        projectItems.not(`${filterStringAnd}`).fadeOut()
        const visibleProjects = projectItems.filter(`${filterStringAnd}`)
        visibleProjects.css("visibility", "visible")
        visibleProjects.fadeIn()
        // visibleProjects.each(function () {
        //   $(this).find(".swiper-initialized")[0].swiper.update()
        // })
      } else {
        projectItems.css("visibility", "visible")
        projectItems.fadeIn()
      }
    } else {
      // no filters are active
      projectItems.css("visibility", "visible")
      projectItems.fadeIn()
    }

    // show hide slides of each projekt swiper
    if (slideFilter != projektArchiveSlideFilterPrev || newItemsOnly) {
      projectItems.each(function () {
        const count = showHideSwiperSlides($(this), slideFilter) // pass in swiper and filter string
        if (count == 0) {
          // no visible slides in the projekt -> hide the projekt
          $(this).fadeOut()
        }
      })

      if (newItemsOnly) projectItems.removeClass("project-archive-item--new-item")

      projektArchiveSlideFilterPrev = slideFilter
    }
  }

  function showHideSwiperSlides(item, slideFilter) {
    let count = 0 // number of visible slides after filtering

    const swiper = item.children(".swiper")

    swiper[0]?.swiper?.destroy() // destroy the swiper

    const slides = swiper.find(".swiper-slide")

    if (slides.length) {
      slides.removeClass("swiper-slide--hidden")

      if (slideFilter != "") {
        const hiddenSlides = slides.not(`.${slideFilter}`)
        hiddenSlides.addClass("swiper-slide--hidden")
        count = slides.length - hiddenSlides.length
      } else {
        // show all
        count = 10
      }
    }

    // recreated the swiper
    initSwiper(item)

    return count // return the number of visible slides
  }

  // parent page sub page links -> scroll to sub page
  $(document).on("click", ".sub-page-link", function (e) {
    e.preventDefault()
    const postItem = $(this).parents(".post-item-container") // get containing post item container

    postItem.find(".sub-page-link").removeClass("sub-page-link--active")
    $(this).addClass("sub-page-link--active") // indicate current active link

    const id = $(this).attr("data-id")
    const activeSubPage = postItem.find(`#${id}`)
    postItem.find(".sub-page-item").removeClass("sub-page-item--active")
    activeSubPage.addClass("sub-page-item--active")
    scrollToElement(activeSubPage)

    // update history
    const ref = $(this).attr("href")
    updateHistory(ref, $(this).text())
  })

  // event handler for the dokumentation sub page link selector
  $(document).on("click", ".doku-sub-page-link", function (e) {
    e.preventDefault()
    const postItem = $(this).parents(".post-item-container") // get containing post item container

    postItem.find(".doku-sub-page-link").removeClass("doku-sub-page-link--active")
    $(this).addClass("doku-sub-page-link--active") // indicate current active link

    const id = $(this).attr("data-id")
    const activeSubPage = postItem.find(`#${id}`)
    postItem.find(".sub-page-item").removeClass("sub-page-item--active")
    activeSubPage.addClass("sub-page-item--active")
    // scrollToElement(activeSubPage)
    // console.log("doku sub page link click", id)

    // update history
    const ref = $(this).attr("href")
    updateHistory(ref, $(this).text())
  })

  // $(document).on("click", ".post-item-content a", function (e) {
  //   // internal link in the same language and not a download link
  //   const target = $(this).attr("target")
  //   const href = $(this).attr("href")
  //   const homeURL = ""
  //   let preventDefault = false

  //   if (target != "_blank") {
  //     const path = href.replace(localized.root_url, "")
  //     const ext = get_url_extension(path)

  //     if (ext == "") {
  //       // not a document

  //       if (href.startsWith(localized.home_url)) {
  //         // internal link
  //         if (languageSlug != "de") {
  //           preventDefault = true
  //         } else {
  //           // if german -> check if link is not for page in other language
  //           if (!href.startsWith(localized.home_url + "/fr/") && !href.startsWith(localized.home_url + "/it/") && !href.startsWith(localized.home_url + "/en/")) {
  //             preventDefault = true
  //           }
  //         }
  //         if (preventDefault) {
  //           e.preventDefault()
  //           // console.log("internal link clicked")
  //           updateHistory(href)
  //           goToWindowLocation(path)
  //         }
  //       }
  //     }
  //   }
  // })

  function get_url_extension(slug) {
    if (slug.includes(".")) return slug.split(".").pop().split(/\#|\?/)[0]
    else return ""
  }

  function setAutoScrollingResetTimeout(ms) {
    ms = ms ?? 400
    clearTimeout(autoScrollingTimeout)
    autoScrollingTimeout = setTimeout(() => {
      autoScrolling = false
    }, ms)
  }

  function scrollToElement($elem) {
    let top = $elem.position().top

    top += siteHeaderHeight

    // console.log("scroll to $elem", $elem.attr("id"), "top:", top, $elem.position().top)

    $("html, body").stop(true, true).animate(
      {
        scrollTop: top
      },
      400
    )
  }

  function getMenuItemBySlug(slug) {
    // console.log("get menu item by slug:", slug)
    let found = false
    let index = 0
    menuItems.each(function (ind) {
      if ($(this).children("a").attr("href").includes(slug)) {
        found = true
        index = ind
        // console.log("menu link found", $(this).children("a").attr("href"), index)
        return false
      }
    })
    if (found) {
      return menuItems.eq(index)
    } else return null
  }

  // ----------------------------------------------------------------------------------------------------------------------------------------------------------------------

  function openBlogPage(postID) {
    postID = postID ?? null // if post id -> scroll to post, else restore scroll position tbd
    // console.log("open the blog page. postID:", postID)

    if (!firstLoad) {
      hideNav()
      // collapseHeader()
    } else {
      firstLoad = false
    }
    if (extMenuOpen) closeExtendedMenu()

    contentContainer.fadeIn()
    // hide all other page and post items
    let blogPage = $("#blog-page")
    $(".post-item-container").not(blogPage).hide()

    setCurrentContentID("blog-page")
    // setCurrentMenuItem(menuItemHome)
    updateLanguageSwitcher()

    if (blogPage.length == 0) {
      // no blog page yet
      // console.log("no blog page yet")
      // $.getJSON(localized.root_url + "/wp-json/wp/v2/posts/", posts => {
      // console.log("posts", posts)

      postsContainer.append(`
        <div id="blog-page" class="post-item-container initialized" data-title="Journal" data-history="journal">
                  
        </div>
                      `)

      blogPage = $("#blog-page")
      blogPage.hide()

      // add load more posts functionality -> create LoadMorePosts object with news page id
      createPostLoader()
      loadMorePosts.loadMorePosts()

      blogPage.fadeIn()
    } else {
      // console.log("blog page exists")

      if (!blogPage.hasClass("initialized")) {
        // console.log("blog page not yet init")

        createPostLoader()
        blogPage.addClass("initialized")
        blogPage.fadeIn()
      } else {
        blogPage.fadeIn()
      }

      // show requested post if id was supplied
      if (postID) {
        const post = blogPage.children(`#${postID}`)
        if (post.length) {
          // tbd
          // console.log("blog post found, show it!", postID)
          showBlogPost(post)
        }
      } else {
        // scroll to top of blog page
        $("html, body").stop(true, true).animate(
          {
            scrollTop: 0
          },
          400
        )
      }
    }
  }

  function createPostLoader() {
    loadMorePosts = new LoadMorePosts("blog-page", languageSlug)
  }

  function openPostWithID(id) {
    // console.log("open single post with id", id)
    let post = $(`#${id}`)
    $("li.menu-item").removeClass("current-menu-item")

    if (post.length) {
      $(".post-item-container").not(post).hide()
      post.fadeIn()
    } else {
      $.getJSON(localized.root_url + "/wp-json/wp/v2/posts/" + id, post => {
        const postHTML = `
          <article id="${post.id}" class="post-item-container post-item-content__post blog-post-item blog-post-item--little-content blog-post-item--in-view" data-id="${post.id}" data-title="${post.title.rendered}" data-href="${post.link}" data-history="${post.slug}">
          ${
            post.featured_image
              ? `
            <div class="blog-post-image">
              <img class="img-container" 
              src="${post.featured_image.src}" 
              srcset="${post.featured_image.srcset}" 
              data-width="${post.featured_image.width}"
              data-height="${post.featured_image.height}"
              sizes="(max-width: 781px) 100vw, 66vw"
              alt="${post.featured_image.alt}">
            </div>
          `
              : ``
          }

          <div class="blog-post-title-date">
              <span>${post.title.rendered}</span><span> / ${post.date_formated}</span>
          </div>

          <div class="blog-post-content">${post.content.rendered}</div>

          <div class="blog-post__show-all-posts">Alle News</div>
           
        </article>
          `

        postsContainer.append(postHTML)
        post = $(`#${id}`)
        $(".post-item-container").not(post).hide()
        post.fadeIn()
        // console.log(post)
      })
    }
  }

  // handle pathname ------------------------------------------------------------------------------------------------
  goToWindowLocation() // after page load to set correct view and menu

  function goToWindowLocation(path) {
    const pathname = path ?? window.location.pathname // == language slug (if not de, de = "") + slug

    // console.log("goto to location:", pathname, window.location.search) // pathname == "/...../" or "/"" (home)  "/fr/downloads/"

    if (pathname === "/" || pathname === `/${languageSlug}/home/`) {
      if (window.location.search == "") {
        // is home page -> page home
        // console.log("is home")
        openPageWithID(homeID)
      } else {
        // is search request -> show results
        // $(".post-item-container").fadeIn()
        // $("li.menu-item").removeClass("current-menu-item")
      }
      // --------------------------------------------------------
    } else {
      // not home
      // first check if it's a menu item. only pages and archive in the menu

      const menuLinks = allMenuLinks.filter(`[href$="${localized.root_url}${pathname}"]`)

      if (menuLinks.length) {
        // console.log("link found in the menu", menuLinks.length)
        // get type of link page, projekte ...
        // also check the additional buero menu tbd
        const menuItem = menuLinks.parent()

        if (menuItem.hasClass("menu-item-object-page")) {
          // console.log("is page menu link")
          if (menuItem.hasClass("template-parent-page")) {
            // console.log("is template parent")
            openPageLink(menuLinks, "template-parent-page")
          } else if (menuItem.hasClass("template-child-page")) {
            // console.log("is template child")
            openPageLink(menuLinks, "template-child-page")
          } else {
            // console.log("is no template")
            openPageLink(menuLinks)
          }
        } else if (menuLinks.parent().hasClass("menu-item-type-post_type_archive")) {
          // is archive link
          // console.log("is archive menu link")
          if (menuLinks.parent().hasClass("menu-item-object-projekt")) {
            // is projekt archive
            openProjectArchive()
          }
        }
      } else {
        // was not a menu link
        // console.log("not menu link")
        // console.log("url is something else")
        // something different -> get post type for path
        $.getJSON(`${localized.root_url}/wp-json/pathtopost/v1/get?path=${pathname}`, post => {
          // console.log(pathname, post)
          if (post.ID > 0) {
            // post found
            if (post.post_type == "page") {
              // page that's not in the menu
              openPageWithID(post.ID, post.template)
            } else if (post.post_type == "post") {
              // check if div with post id exists. (when post was loaded by single.php)
              const postItem = $(`#${post.ID}`)
              const blogPage = $(`#blog-page`)
              if (postItem.length && !blogPage.length) {
                // php loaded single post item exists, but no blog page container
                // hide all other post items
                $(".post-item-container").not(`#${post.ID}`).hide()
                $("li.menu-item").removeClass("current-menu-item")
                $(`#${post.ID}`).fadeIn()
              } else {
                // open single post
                openPostWithID(post.ID)
              }
            } else if (post.post_type == "projekt") {
              // console.log("is a single projekt")
              openProjektByID(post.ID)
            } else {
              // hide all other post items
              // console.log("some other post -> show it")
              $(".post-item-container").not(`#${post.ID}`).hide()
              $("li.menu-item").removeClass("current-menu-item")
              $(`#${post.ID}`).fadeIn()
            }
          } else if (post.isProjektArchive) {
            // console.log("is projekt archive")
            openProjectArchive()
          } else {
            // console.log("error not a post")
            // is not a post -> show all post item container
            $(".post-item-container").fadeIn()
            $("li.menu-item").removeClass("current-menu-item")
          }
        })
      }
    }
  }

  //
  function setCurrentContentID(id) {
    prevContentID = currentContentID
    currentContentID = id
    // console.log("current content:", currentContentID, prevContentID)
  }

  let resizeTimeout = null
  let resizeTimeout2 = null

  // resize event handler
  window.addEventListener("resize", () => {
    checkMobileView()

    clearTimeout(resizeTimeout)
    resizeTimeout = setTimeout(() => {
      touchSupport()
      onOrientationChange()
      setAdminBarHeight()
      calcVhPropertyFromClientHeight()
      headerHeight()
      checkMobileView()
      headerHeight()
      // setCurrentMenuItem(allMenuItems.filter(".current-menu-item")) // find current menu and set it
      //checkSwipersEnable()
    }, 500)

    clearTimeout(resizeTimeout2)
    resizeTimeout2 = setTimeout(() => {
      // for ios safari to get correct window height
      calcVhPropertyFromClientHeight()
    }, 1000)
  })

  function checkSwipersEnable() {
    const homepage = $(`#${homeID}`)

    if (homepage.length && homepage.is(":visible") && pageLoader) {
      // homepage exists and is visible
      // console.log("home is visible")
      const swiper = homepage.find(".site-swiper.swiper-initialized")
      if (swiper.length) {
        const settings = pageLoader.shouldBeEnabledVertical(swiper[0])
        // console.log("shouldbeenabled", settings)
        if (settings == null) {
          // console.log("site swiper should be enabled")
          // swiper[0].swiper.enable()
          // swiper[0].swiper.update()
        } else {
          // swiper[0].swiper.disable()
          // console.log("site swiper should be disabled")
        }
      }
    }
  }

  function touchSupport() {
    touchsupport = "ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

    if (!touchsupport) {
      // browser doesn't support touch
      // document.documentElement.className += " non-touch"
      $("html").addClass("non-touch")
      $("html").removeClass("touch")
    } else {
      $("html").removeClass("non-touch")
      $("html").addClass("touch")
    }
  }

  function checkMobileView() {
    if ($(window).width() < mobileBreakpoint) {
      mobileView = true
      $("html").addClass("mobile-view")
      $("html").removeClass("desktop-view")
    } else {
      // desktop view
      mobileView = false
      $("html").removeClass("mobile-view")
      $("html").addClass("desktop-view")
      // reset mobile header settings
      siteTitle.css("height", "")
      siteTitle.css("padding", "")
      siteTitleLink.css("padding", "")
    }
    // console.log("window width", $(window).width());
  }

  function onOrientationChange() {
    // detect orientation change
    if ((orientationLandscape && window.innerWidth < window.innerHeight) || (!orientationLandscape && window.innerWidth >= window.innerHeight)) {
      setLandscape()
    }
  }

  function setLandscape() {
    // store new orientation
    orientationLandscape = window.innerWidth >= window.innerHeight

    if (orientationLandscape) {
      $("html").addClass("orientation-landscape")
      $("html").removeClass("orientation-portrait")
    } else {
      $("html").removeClass("orientation-landscape")
      $("html").addClass("orientation-portrait")
    }
    // console.log("orientation changed, landscape:", orientationLandscape);
  }

  function setAdminBarHeight() {
    let wpabh = 0
    const adminBar = $("#wpadminbar")
    if (adminBar.length) {
      wpabh = adminBar.outerHeight()
    }
    adminBarHeight = wpabh
    $(":root").css("--adminBarHeight", `${adminBarHeight}px`)
  }

  function headerHeight() {
    siteHeaderHeight = siteHeader.outerHeight()
    $(":root").css("--siteHeaderHeight", `${siteHeaderHeight}px`)
  }

  function calcVhPropertyFromClientHeight() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    // let vh = document.documentElement.clientHeight * 0.01
    const vh = window.innerHeight * 0.01
    // console.log("height: ", vh * 100);
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    // if (debug) console.log("client height: ", document.documentElement.clientHeight);
  }

  // reload page on broswer back / forward
  window.onpopstate = function (event) {
    // console.log("onpopstate", event, window.location)
    goToWindowLocation()
  }

  // page scrollscroll handler -> show hide site header
  document.addEventListener(
    "scroll",
    function (e) {
      // const currentScroll = window.pageYOffset
      const currentScroll = window.scrollY
      const scrollDelta = 0.5 * (prevScrollDelta + (currentScroll - lastScroll))
      // console.log("scroll: ", currentScroll, lastScroll, scrollDelta, prevScrollDelta)

      if (!autoScrolling && !menuChanging) {
        scrollYPositions[currentContentID] = currentScroll
        // console.log(scrollYPositions)
      }

      prevScrollDelta = scrollDelta

      if (window.scrollY === 0 && !menuChanging) {
        // on top
        // console.log("on top")
        hideNav() // unstick nav menu
        // check if current page is the top page tbd. intersection observer misses when scrolling too fast
      } else if (currentScroll > lastScroll && scrollDelta > 5 && !autoScrolling && !menuChanging) {
        // DOWN ---
        // console.log("down -")
        hideNav()
      } else if (currentScroll < lastScroll && scrollDelta < -10 && currentScroll > siteHeaderHeight && !autoScrolling && !menuChanging) {
        // UP --- UP
        // console.log("up")
        showNav()
      } else if (currentScroll > 1 && !autoScrolling) {
        //hideNav()
      }

      lastScroll = Math.max(0, currentScroll)
    },
    supportsPassive ? { passive: true } : false
  )

  function getObjectIDFromMenuItem(elem) {
    // get page id from menu item classlist (wpse-object-id-XX)
    let classlist = elem.attr("class").split(/\s+/)
    let pageID
    $.each(classlist, function (index, item) {
      if (item.startsWith("wpse-object-id-")) {
        const splits = item.split("wpse-object-id-")
        if (splits.length > 1) pageID = splits[1]
        return false
      }
    })
    return pageID
  }

  function checkFlexGap() {
    // create flex container with row-gap set
    var flex = document.createElement("div")
    flex.style.display = "flex"
    flex.style.flexDirection = "column"
    flex.style.rowGap = "1px"
    flex.style.position = "absolute"

    // create two, elements inside it
    flex.appendChild(document.createElement("div"))
    flex.appendChild(document.createElement("div"))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
  }

  function observeElement($this) {
    if (observer) {
      observer.disconnect()
    } else {
      observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // console.log("ENTER")
          } else {
            // console.log("LEAVE")
          }
        },
        {
          root: null,
          threshold: 0.01 // set offset 0 means trigger if atleast 0% of element in viewport
        }
      )
    }
    observer.observe($this[0])
  }

  function buildMenuData() {
    const menuData = []
    $(".menu-main > .menu-item")
      .add(".menu-download > .menu-item")
      .add(".menu-faq > .menu-item")
      .each(function (index) {
        // console.log("top level menu item", $(this))
        const menuItem = {
          pageID: getObjectIDFromMenuItem($(this)),
          slug: $(this).children("a").text(),
          subMenus: []
        }

        // get 1st level submenus
        const subMenus = $(this).children(".sub-menu")?.children(".menu-item")
        subMenus.each(function () {
          // console.log("sub level menu item", $(this).attr("id"))
          const subMenuItem = {
            pageID: getObjectIDFromMenuItem($(this)),
            slug: $(this).children("a").text(),
            subMenus: []
          }

          // get all 2nd level submenus
          const subSubMenus = $(this).children(".sub-menu")?.children(".menu-item")
          subSubMenus.each(function () {
            const subSubMenuItem = {
              pageID: getObjectIDFromMenuItem($(this)),
              slug: $(this).children("a").text()
            }
            subMenuItem.subMenus.push(subSubMenuItem)
          })

          menuItem.subMenus.push(subMenuItem)
        })
        menuData.push(menuItem)
      })
    // console.log("menu data", menuData)
    return menuData
  }

  function restructureMainMenu() {
    // take the first 3 top level menus and the remaining menus and put them in 2 separate container divs
    // for the extended menu. this allow the menu to be split in two columns

    const topMenuItems = menuMain.children(".menu-item")

    if (topMenuItems.length >= 2) {
      const $leftContainer = $('<div class="container-left"></div>').appendTo(menuMain)
      const $rightContainer = $('<div class="container-right"></div>').appendTo(menuMain)
      for (let i = 0; i < topMenuItems.length; i++) {
        if (i <= Math.floor(topMenuItems.length / 2)) {
          topMenuItems.eq(i).appendTo($leftContainer)
        } else {
          topMenuItems.eq(i).appendTo($rightContainer)
        }
      }
    }
  }

  function checkSupportsPassive() {
    // Test via a getter in the options object to see if the passive property is accessed
    try {
      var opts = Object.defineProperty({}, "passive", {
        get: function () {
          supportsPassive = true
        }
      })
      window.addEventListener("testPassive", null, opts)
      window.removeEventListener("testPassive", null, opts)
    } catch (e) {}
  }
})
